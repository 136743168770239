import { useEffect, useState, useContext } from "react";
import { Form, Input, Row, Col, Button, notification } from "antd";
import LoadingAni from "../Layout/loading";
import PageHeader from "../Layout/pageHeader";
import axios from "axios";
import AuthContext from "../../context/authContext";
import { Helmet } from "react-helmet";

const { TextArea } = Input;

export default function ProjectField() {
  const authState = useContext(AuthContext);
  const token = authState.auth.token;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [form] = Form.useForm();

  const getData = () => {
    setLoading(true);
    axios
      .get(
        "https://office.hempcarbonstandard.org/projectdetail/project-fields/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((d) => {
        setData(d.data[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong, fetching data, try again.",
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 8,
      offset: 0,
    },
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        category: data.category,
        standard: data.standard,
        project_type: data.project_type,
        validator: data.validator,
        impacts_on_carbon_markets: data.impacts_on_carbon_markets,
        un_report_link: data.un_report_link,
      });
    }
  }, [data]);

  const onFinish = (values) => {
    setLoading(true);
    if (data) {
      axios
        .put(
          `https://office.hempcarbonstandard.org/projectdetail/project-fields/${data.id}/`,
          {
            category: values.category,
            standard: values.standard,
            project_type: values.project_type,
            validator: values.validator,
            impacts_on_carbon_markets: values.impacts_on_carbon_markets,
            un_report_link: values.un_report_link,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((d) => {
          setLoading(false);
          getData();
          notification["success"]({
            message: "Success !!",
            description: "Project fields updated.",
          });
        })
        .catch(() => {
          setLoading(false);
          notification["error"]({
            message: "Error !!",
            description: "Something went wrong, try again.",
          });
        });
    } else {
      axios
        .post(
          "https://office.hempcarbonstandard.org/projectdetail/project-fields/",
          {
            category: values.category,
            standard: values.standard,
            project_type: values.project_type,
            validator: values.validator,
            impacts_on_carbon_markets: values.impacts_on_carbon_markets,
            un_report_link: "https://" + values.un_report_link,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((d) => {
          setLoading(false);
          getData();
          form.resetFields();
          notification["success"]({
            message: "Success !!",
            description: "Project fields created.",
          });
        })
        .catch(() => {
          setLoading(false);
          notification["error"]({
            message: "Error !!",
            description: "Something went wrong, try again.",
          });
        });
    }
  };

  return loading ? (
    <LoadingAni />
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hemp Carbon Standard</title>
      </Helmet>
      <div
        className="W-[100%] bg-white rounded-md"
        style={{
          background: "white",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <PageHeader name="Project Fields" />

        <Form
          {...formItemLayout}
          layout="vertical"
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          className="bg-white w-full px-8 pt-0 pb-4 rounded-lg"
          style={{
            background: "white",
            width: "100%",
            padding: "0px 24px",
            paddingBottom: "8px",
            borderRadius: "12px",
          }}
        >
          <Row
            gutter={[32, 32]}
            className="pt-8"
            style={{
              paddingTop: "32px",
            }}
          >
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name="category"
                label="Category"
                rules={[
                  {
                    required: true,
                    message: "Category missing !!",
                  },
                ]}
              >
                <Input placeholder="input category" maxLength={240} />
              </Form.Item>
              <Form.Item
                name="project_type"
                label="Project Type"
                rules={[
                  {
                    required: true,
                    message: "Project Type missing !!",
                  },
                ]}
              >
                <Input placeholder="input project type" maxLength={240} />
              </Form.Item>
              <Form.Item
                name="impacts_on_carbon_markets"
                label="Impacts on the Carbon Markets"
                rules={[
                  {
                    required: true,
                    message: "Impacts on the Carbon Markets missing !!",
                  },
                ]}
              >
                <TextArea
                  rows={4}
                  placeholder="write about impacts on the carbon markets"
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name="standard"
                label="Standard"
                rules={[
                  {
                    required: true,
                    message: "Standard missing !!",
                  },
                ]}
              >
                <Input placeholder="input standard" maxLength={240} />
              </Form.Item>
              <Form.Item
                name="validator"
                label="Validator"
                rules={[
                  {
                    required: true,
                    message: "Validator missing !!",
                  },
                ]}
              >
                <Input placeholder="input validator" maxLength={240} />
              </Form.Item>
              <Form.Item
                name="un_report_link"
                label="UN Report Link"
                tooltip="Format required - www.example.com"
                rules={[
                  {
                    required: true,
                    message: "UN Report Link missing !!",
                  },
                ]}
              >
                {data ? (
                  <Input placeholder="input UN Report link" />
                ) : (
                  <Input
                    addonBefore="https://"
                    placeholder="input UN Report link"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Form.Item {...buttonItemLayout}>
            <Button htmlType="submit" type="primary">
              {data ? "Update Project Fields" : "Create Project Fields"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
