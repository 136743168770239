import { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  message,
  Checkbox,
  Upload,
  Card,
  Modal,
  DatePicker,
  InputNumber,
  notification,
  List,
  Image,
} from "antd";

import PageHeader from "../Layout/pageHeader";
import LoadingAni from "../Layout/loading";
import { useSearchParams } from "react-router-dom";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment/moment";
import AuthContext from "../../context/authContext";
import { Helmet } from "react-helmet";


const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

export default function Update() {
  const [searchParams] = useSearchParams();
  const authState = useContext(AuthContext);
  const token = authState.auth.token;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [checkBoxOptions] = useState(["Forward", "Pending", "Issued"]);
  const [checkedList, setCheckedList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [nameFileList, setNameFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [apiData, setApiData] = useState(null);
  const [fieldsNamesFromApi, setFieldsNamesFromApi] = useState(null);
  const [fieldsNamesToRemove, setFieldsNamesToRemove] = useState([]);

  useEffect(() => {
    const id = searchParams.get("id");
    getDataForId(id);
  }, []);

  const getDataForId = (id) => {
    setLoading(true);
    axios
      .get(
        `https://office.hempcarbonstandard.org/projectdetail/create-project/${id}/`,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )

      .then((d) => {
        setApiData(d.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong try again.",
        });
      });
  };

  useEffect(() => {
    if (apiData) {
      form.setFieldsValue({
        farm: apiData.farm,
        state: apiData.state,
        country: apiData.country,
        about_project: apiData.about_project,
        total_acres: apiData.total_acres,
        estimated_carbon_credit: apiData.estimated_carbon_credit,
        actual_carbon_credit: apiData.actual_carbon_credit,

        forward_sold: apiData.forward_sold,
        actual_sold: apiData.actual_sold,

        project_duration: apiData.project_duration,
        expected_validation_date: moment(
          apiData.expected_validation_date.toString(),
          "YYYY-MM-DD"
        ),
        validation_completed_date:
          apiData.validation_completed_date === null
            ? undefined
            : moment(
                apiData.validation_completed_date.toString(),
                "YYYY-MM-DD"
              ),
        status: apiData.status.map((d) => d.value),
        picture_of_farm: apiData.picture_of_farm,
      });
      const aa = [
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: apiData.picture_of_farm,
        },
      ];
      setFileList(aa);
      setFieldsNamesFromApi(apiData.filenames);

      // const shapeField = apiData?.shapefields?.map((d) => ({
      //   uid: d.id,
      //   name: "image.png",
      //   status: "done",
      //   url: d.img,
      // }));
      // setShapeSieldsFromApi(shapeField);
    }
  }, [apiData]);

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 8,
      offset: 0,
    },
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 24,
        offset: 0,
      },
    },
  };

  const onChangeCheckBox = (list) => {
    setCheckedList(list);
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleChangeNameFile = ({ fileList: newFileList }) => {
    if (Array.isArray(newFileList) && newFileList.length !== 0) {
      setNameFileList([...nameFileList, newFileList]);
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return Upload.LIST_IGNORE;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const onFinish = (values) => {
    if (Array.isArray(fileList) && fileList.length === 0) {
      notification["error"]({
        message: "Error !!",
        description: (
          <p>
            Please add <b>Fram Image</b>
          </p>
        ),
      });
    } else {
      const expected_validation_date =
        values.expected_validation_date.format("YYYY-MM-DD");
      const picture_of_farm = fileList[0]?.originFileObj;

      let data = new FormData();

      if (fileList[0]?.originFileObj) {
        data.append("picture_of_farm", picture_of_farm);
      }

      if (values.fieldNames) {
        const dd = values.fieldNames.map((d) => ({
          a: d.first,
        }));
        data.append("fieldnames", JSON.stringify(dd));
      }

      if (values.fieldNames) {
        values.fieldNames.forEach((d) =>
          data.append("fieldNamesImages", d.last.file)
        );
      }

      if (values.actual_carbon_credit) {
        data.append("actual_carbon_credit", values.actual_carbon_credit);
      }

      if (values.validation_completed_date) {
        const ddate = values.validation_completed_date.format("YYYY-MM-DD");
        data.append("validation_completed_date", ddate);
      }

      data.append("farm", values.farm);
      data.append("state", values.state);
      data.append("country", values.country);
      data.append("about_project", values.about_project);
      data.append("total_acres", values.total_acres);
      data.append("estimated_carbon_credit", values.estimated_carbon_credit);
      data.append("project_duration", values.project_duration);
      data.append("expected_validation_date", expected_validation_date);
      data.append("status", JSON.stringify(values.status));

      data.append("fieldsNamesToRemove", JSON.stringify(fieldsNamesToRemove));

      setLoading(true);
      axios({
        url: `https://office.hempcarbonstandard.org/projectdetail/create-project/${apiData.id}/`,
        method: "PUT",
        data,
        headers: {
          Authorization: `Token ${token}`,
          Accept: "application/json",
        },
      })
        .then((d) => {
          setLoading(false);
          form.resetFields();
          // alert(d.data.id)
          setFieldsNamesToRemove([]);
          setNameFileList([]);
          getDataForId(d.data.id);
          notification["success"]({
            message: "Success !!",
            description: "Project Updated.",
          });
        })
        .catch((e) => {
          setLoading(false);
          notification["error"]({
            message: "Error !!",
            description: "Something went wrong, try again.",
          });
        });
    }
  };

  return loading ? (
    <LoadingAni />
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hemp Carbon Standard</title>
      </Helmet>

      <div
        className="W-[100%] bg-white rounded-md"
        style={{
          background: "white",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <PageHeader name="Update Project" />

        <Form
          {...formItemLayout}
          layout="vertical"
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          className="bg-white w-full px-8 pt-0 pb-4 rounded-lg"
          style={{
            background: "white",
            width: "100%",
            padding: "0px 24px",
            paddingBottom: "8px",
            borderRadius: "12px",
          }}
        >
          <Row
            gutter={[32, 32]}
            className="pt-8"
            style={{
              paddingTop: "32px",
            }}
          >
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name="farm"
                label="Farm / Business Name"
                rules={[
                  {
                    required: true,
                    message: "Farm / Business Name missing !!",
                  },
                ]}
              >
                <Input placeholder="input farm business name" />
              </Form.Item>
              <Form.Item
                name="about_project"
                label="About Project"
                rules={[
                  {
                    required: true,
                    message: "About Project missing !!",
                  },
                ]}
              >
                <TextArea rows={4} placeholder="write about project" />
              </Form.Item>
              <Form.Item
                name="total_acres"
                label="Total Acres"
                rules={[
                  {
                    required: true,
                    message: "Total Acres missing !!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="input total acres"
                  controls={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: true,
                    message: "Select at least one status !!",
                  },
                ]}
              >
                <CheckboxGroup
                  options={checkBoxOptions}
                  value={checkedList}
                  onChange={onChangeCheckBox}
                />
              </Form.Item>

              <Form.Item
                name="project_duration"
                label="Project Duration"
                rules={[
                  {
                    required: true,
                    message: "Project Duration missing !!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="input project duration"
                  controls={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="expected_validation_date"
                label="Expected Validation Date"
                rules={[
                  {
                    required: true,
                    message: "Expected Validation Date missing !!",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name="state"
                label="State / County"
                rules={[
                  {
                    required: true,
                    message: "State / County missing !!",
                  },
                ]}
              >
                <Input placeholder="input state / sounty" />
              </Form.Item>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "Country missing !!",
                  },
                ]}
              >
                <Input placeholder="input country" />
              </Form.Item>
              <Form.Item
                name="estimated_carbon_credit"
                label="Estimated Carbon Credits"
                rules={[
                  {
                    required: true,
                    message: "Estimated Carbon Credits missing !!",
                  },
                ]}
              >
                <InputNumber
                  disabled={apiData?.forward_completed}
                  placeholder="input estimated carbon credits"
                  controls={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item name="forward_sold" label="Forward Sold">
                <InputNumber disabled style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                name="actual_carbon_credit"
                label="Actual Carbon Credits"
              >
                <InputNumber
                  disabled={apiData?.actual_completed}
                  placeholder="input actual carbon credits"
                  controls={false}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Form.Item name="actual_sold" label="Actual Sold">
                <InputNumber disabled style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                name="validation_completed_date"
                label="Validation Completed Date"
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row
            gutter={[32, 32]}
            className="pt-8"
            style={{
              paddingTop: "10px",
            }}
          >
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Card title="Farm Picture" headStyle={{ background: "#f5f5f5" }}>
                <Form.Item
                  name="picture_of_farm"
                  label="Farm Picture"
                  rules={[
                    {
                      required: true,
                      message: "Farm Image missing !!",
                    },
                  ]}
                >
                  <Upload
                    beforeUpload={beforeUpload}
                    //   {...uploadProps}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    onRemove={() => {
                      setFileList([]);
                    }}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </Form.Item>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Card
                title="Field Name / Names"
                headStyle={{ background: "#f5f5f5" }}
              >
                <Row>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <List
                      style={{
                        background: "white",
                        width: "100%",
                        padding: "0px 24px",
                        paddingBottom: "8px",
                        borderRadius: "12px",
                      }}
                      loading={loading}
                      itemLayout="vertical"
                      size="small"
                      dataSource={fieldsNamesFromApi ? fieldsNamesFromApi : []}
                      renderItem={(item) => (
                        <List.Item
                          key={item.name}
                          extra={
                            <Image width={180} height={100} src={item.img} />
                          }
                          // actions
                          // actions={[<Button>action</Button>]}
                        >
                          <List.Item.Meta
                            title={`Name: ${item.name}`}
                            description={
                              <Button
                                type="text"
                                danger
                                onClick={() => {
                                  setFieldsNamesFromApi(
                                    fieldsNamesFromApi.filter(
                                      (d) => d.id !== item.id
                                    )
                                  );
                                  setFieldsNamesToRemove(
                                    (fieldsNamesToRemove) => [
                                      ...fieldsNamesToRemove,
                                      item.id,
                                    ]
                                  );
                                }}
                              >
                                Remove
                              </Button>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                  <Col xs={{ span: 24 }} md={{ span: 12 }}>
                    <Form.List name="fieldNames">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            // <Space
                            //   key={key}
                            //   style={{
                            //     display: "flex",
                            //     marginBottom: 8,
                            //     // style={{
                            //     background: "red",
                            //     width: "100%",
                            //     // }}
                            //   }}
                            //   align="baseline"
                            // >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                              key={key}
                            >
                              <Form.Item
                                {...restField}
                                name={[name, "first"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing first name",
                                  },
                                ]}
                                style={{
                                  width: "50%",
                                }}
                              >
                                <Input placeholder="First Name" />
                              </Form.Item>
                              <Form.Item
                                {...restField}
                                name={[name, "last"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Missing last name",
                                  },
                                ]}
                              >
                                <Upload
                                  beforeUpload={beforeUpload}
                                  //   {...uploadProps}
                                  showUploadList={{ showRemoveIcon: false }}
                                  listType="picture-card"
                                  fileList={nameFileList[key]}
                                  onPreview={handlePreview}
                                  onChange={handleChangeNameFile}
                                  onRemove={() => {
                                    let de = nameFileList.filter(
                                      (d) => d !== d[key]
                                    );
                                    setNameFileList(de);
                                  }}
                                >
                                  {/* {uploadButton} */}
                                  {nameFileList[key]
                                    ? nameFileList[key].length >= 1
                                      ? null
                                      : uploadButton
                                    : uploadButton}
                                </Upload>
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                              {/* </Space> */}
                            </div>
                          ))}
                          <Form.Item {...formItemLayoutWithOutLabel}>
                            <Button
                              type="primary"
                              onClick={() => add()}
                              // block
                              icon={<PlusOutlined />}
                            >
                              Add field names
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Col xs={{ span: 24 }} md={{ span: 24 }}>
              <Card title="Shape File" headStyle={{ background: "#f5f5f5" }}>
                <Form.Item name="shape_file" label="Shape File">
                  <Upload
                    beforeUpload={beforeUpload}
                    //   {...uploadProps}
                    listType="picture-card"
                    fileList={shapeFieldsFromApi}
                    onPreview={handlePreview}
                    onChange={handleChangeShapeFile}
                    onRemove={() => {
                      alert("hi");
                    }}
                  >
                    {uploadButton}
                  </Upload>
                </Form.Item>
              </Card>
            </Col>
          </Row> */}

          <br />
          <Form.Item {...buttonItemLayout}>
            <Button htmlType="submit" type="primary">
              Update Project
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        open={previewOpen}
        title={previewTitle}
        width="55%"
        footer={null}
        onCancel={() => {
          setPreviewOpen(false);
        }}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}
