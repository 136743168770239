import { useState } from "react";
import AuthContext from "./authContext";

const AuthState = (props) => {
  const state = {
    token: null,
    loadingLogin: false,
    userData: {},
  };
  const [auth, setAuth] = useState(state);
  const update = (value) => {
    setAuth(value);
  };
  return (
    <AuthContext.Provider value={{ auth, update }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
