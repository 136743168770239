import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function ConsoleNotFound() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hemp Carbon Standard</title>
      </Helmet>
      <div
        className="flex justify-center h-screen items-center"
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Link to="/console">
              <Button type="primary">Back Home</Button>
            </Link>
          }
        />
      </div>
    </>
  );
}
