import { Route, Routes } from "react-router-dom";
import ConsoleNotFound from "./consoleNotFound";
import Project from "./pages/newProject";
import ListProjects from "./pages/listProjects";
import Update from "./pages/update";
import ProjectField from "./pages/projectField";
import Price from "./pages/price";
import Retirement from "./pages/retirement";
import RetirementList from "./pages/retirementList";
import RetirementUpdate from "./pages/retirementUpdate";
import UpdateProject from "./pages/updateProject";
export default function ConsoleRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Project />} />
      <Route path="list-project" element={<ListProjects />} />
      <Route path="update" element={<Update />} />
      <Route path="project-fields" element={<ProjectField />} />
      <Route path="prices" element={<Price />} />
      <Route path="update-project" element={<UpdateProject />} />
      <Route path="retirement" element={<Retirement />} />
      <Route path="retirement-list" element={<RetirementList />} />
      <Route path="uppdate-retirement" element={<RetirementUpdate />} />
      <Route path="*" element={<ConsoleNotFound />} />
    </Routes>
  );
}
