import { useState, useEffect, useContext } from "react";
import LoadingAni from "../Layout/loading";
import { Form, Row, Col, Button, InputNumber, notification } from "antd";
import PageHeader from "../Layout/pageHeader";
import AuthContext from "../../context/authContext";
import axios from "axios";
import { Helmet } from "react-helmet";


export default function Price() {
  const authState = useContext(AuthContext);
  const token = authState.auth.token;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const formItemLayout = {
    labelCol: {
      span: 24,
    },
    wrapperCol: {
      span: 24,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 12,
      offset: 3,
    },
  };

  const getData = () => {
    setLoading(true);
    axios
      .get("https://office.hempcarbonstandard.org/projectdetail/prices/", {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((d) => {
        setData(d.data[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong fetching data, try again.",
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        forward: data.forward_credit_price,
        actual: data.actual_credit_price,
      });
    }
  }, [data]);

  const onFinish = (values) => {
    setLoading(true);
    if (data) {
      axios
        .put(
          `https://office.hempcarbonstandard.org/projectdetail/prices/${data.id}/`,
          {
            forward_credit_price: values.forward,
            actual_credit_price: values.actual,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((d) => {
          setLoading(false);
          form.resetFields();
          getData();
          notification["success"]({
            message: "Success !!",
            description: "Prices updated.",
          });
        })
        .catch(() => {
          setLoading(false);
          notification["error"]({
            message: "Error !!",
            description: "Something went wrong, try again.",
          });
        });
    } else {
      axios
        .post(
          "https://office.hempcarbonstandard.org/projectdetail/prices/",
          {
            forward_credit_price: values.forward,
            actual_credit_price: values.actual,
          },
          {
            headers: {
              Authorization: `Token ${token}`,
            },
          }
        )
        .then((d) => {
          setLoading(false);
          getData();
          form.resetFields();
          notification["success"]({
            message: "Success !!",
            description: "Prices created.",
          });
        })
        .catch(() => {
          setLoading(false);
          notification["error"]({
            message: "Error !!",
            description: "Something went wrong, try again.",
          });
        });
    }
  };

  return loading ? (
    <LoadingAni />
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hemp Carbon Standard</title>
      </Helmet>
      <div
        className="W-[100%] bg-white rounded-md"
        style={{
          background: "white",
          borderRadius: "10px",
          width: "100%",
        }}
      >
        <PageHeader name="Prices" />

        <Form
          {...formItemLayout}
          layout="vertical"
          form={form}
          scrollToFirstError
          onFinish={onFinish}
          className="bg-white w-full px-8 pt-0 pb-4 rounded-lg"
          style={{
            background: "white",
            width: "100%",
            padding: "0px 24px",
            paddingBottom: "8px",
            borderRadius: "12px",
          }}
        >
          <Row
            gutter={[32, 32]}
            className="pt-8"
            style={{
              paddingTop: "32px",
            }}
          >
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name="forward"
                label="Forward Credit Price"
                rules={[
                  {
                    required: true,
                    message: "Forward Credit Price missing !!",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  precision={0}
                  placeholder="input foward credit price"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }}>
              <Form.Item
                name="actual"
                label="Actual Credit Price"
                rules={[
                  {
                    required: true,
                    message: "Actual Credit Price missing !!",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  precision={0}
                  placeholder="input actual credit price"
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
            </Col>

            <br />
            <Form.Item {...buttonItemLayout}>
              <Button htmlType="submit" type="primary">
                {data ? "Update Price" : "Add Price"}
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </div>
    </>
  );
}
