import React, { useEffect, useState, useContext } from "react";
import {
  Layout,
  Button,
  Menu,
  Dropdown,
  Modal,
  Form,
  Avatar,
  notification,
  Input,
} from "antd";
import {
  DollarOutlined,
  FormOutlined,
  CopyOutlined,
  AppstoreOutlined,
  LockOutlined,
} from "@ant-design/icons";

import ConsoleRoutes from "../consoleRoutes";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../context/authContext";

const { Content, Sider } = Layout;

export default function ConsoelLayout() {
  const [form] = Form.useForm();
  const authState = useContext(AuthContext);

  const [collapsed, setCollapsed] = useState(false);
  const [showModel, setShowModal] = useState(false);
  const [loadingg, setLoadingg] = useState(false);

  const token = authState.auth.token;

  useEffect(() => {
    if (token) {
    } else {
      const tokenn = localStorage.getItem("token");
      if (tokenn) {
        authState.update({
          ...authState.auth,
          token: tokenn,
        });
      } else {
        navigate("/");
      }
      // window.location.reload();
    }
  }, [token]);

  const navigate = useNavigate();

  const formItemLayout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 14,
    },
  };

  const buttonItemLayout = {
    wrapperCol: {
      span: 14,
      offset: 10,
    },
  };

  const items = [
    {
      label: "Create Project",
      key: "0",
      icon: <FormOutlined />,
      onClick: () => {
        navigate("/console");
      },
    },

    {
      label: "List Project",
      key: "1",
      icon: <CopyOutlined />,
      onClick: () => {
        navigate("/console/list-project");
      },
    },
    {
      label: "Project Fields",
      key: "2",
      icon: <AppstoreOutlined />,
      onClick: () => {
        navigate("/console/project-fields");
      },
    },

    {
      label: "Prices",
      key: "3",
      icon: <DollarOutlined />,
      onClick: () => {
        navigate("/console/prices");
      },
    },

    {
      label: "Update Retirement",
      key: "4",
      icon: <DollarOutlined />,
      onClick: () => {
        navigate("/console/update-project");
      },
    },
    {
      label: "New Retirement",
      key: "5",
      icon: <DollarOutlined />,
      onClick: () => {
        navigate("/console/retirement");
      },
    },
    {
      label: "Retirement List",
      key: "6",
      icon: <DollarOutlined />,
      onClick: () => {
        navigate("/console/retirement-list");
      },
    },

  ];

  const dropdownItems = [
    {
      label: "Change Password",
      key: "0",
      onClick: () => {
        setShowModal(true);
      },
    },
    {
      type: "divider",
    },
    {
      label: "Log Out",
      key: "1",
      onClick: () => {
        localStorage.removeItem("token");
        authState.update({
          ...authState.auth,
          token: null,
        });
      },
    },
  ];

  const handleChangePassword = (values) => {
    if (values.password !== values.confirm) {
      notification["error"]({
        message: "Error !!",
        description: "Password does not match.",
      });
      return;
    }
    setLoadingg(true);
    axios
      .post(
        "https://office.hempcarbonstandard.org/dj-rest-auth/password/change/",
        {
          old_password: values.current,
          new_password1: values.password,
          new_password2: values.password,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((d) => {
        setLoadingg(false);

        notification["success"]({
          message: "Success !!",
          description: "Password Changed.",
        });
        form.resetFields();
        setShowModal(false);
      })
      .catch(() => {
        setLoadingg(false);
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong, try again..",
        });
      });
  };

  return token ? (
    <>
      <Layout style={{ height: "100vh" }}>
        <header
          style={{
            height: "50px",
            minHeight: "50px",
            padding: "0px 20px",
            margin: 0,
            background: "#f5f5f5",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <img
              src="/logo-color.png"
              alt="logo"
              style={{
                width: "55px",
                height: "45px",
              }}
            ></img>
          </div>
          <Dropdown
            trigger={["click"]}
            menu={{
              items: dropdownItems,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Avatar
                shape="circle"
                size="large"
                style={{
                  borderRadius: "50%",
                  border: "2px solid #bfbfbf",
                }}
                // icon={<UserOutlined />}
                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              />
            </a>
          </Dropdown>
        </header>

        <Layout>
          <Sider
            collapsible
            onCollapse={() => {
              setCollapsed(!collapsed);
            }}
            collapsed={collapsed}
          // theme="light"
          >
            <Menu
              style={{ marginTop: "20px" }}
              theme="dark"
              // defaultSelectedKeys={["0"]}
              mode="inline"
              items={items}
            />
          </Sider>
          {/* Start of left sider */}

          <Layout
            style={{
              background: "#d9d9d9",
              overflow: "auto",
              paddingLeft: "12px",
              paddingRight: "12px",
              paddingTop: "10px",
            }}
            className="overflow-auto pl-12 pr-4 pt-4 bg-[#d9d9d9]"
          >
            <Content>
              <div className="pb-2" style={{ paddingBottom: "8px" }}>
                <ConsoleRoutes />
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>

      <Modal
        centered
        footer={null}
        loading={loadingg}
        title="Change password"
        open={showModel}
        destroyOnClose={true}
        onCancel={() => {
          setShowModal(false);
          //   form.resetFields();
        }}
      >
        <div
          className="py-6 px-6 mt-6"
          style={{
            padding: "24px 12px",
            marginTop: "24px",
          }}
        >
          <Form
            name="normal_login"
            form={form}
            {...formItemLayout}
            onFinish={handleChangePassword}
            labelAlign="left"
          >
            <Form.Item
              label="Current password"
              name="current"
              rules={[
                {
                  required: true,
                  message: "Input password",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item
              name="password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Input password",
                },
                {
                  min: 4,
                  message: "Minimum 4 characters required.",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              rules={[
                {
                  required: true,
                  message: "Confirm password",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Confirm Password"
              />
            </Form.Item>
            <Form.Item {...buttonItemLayout}>
              <Button block htmlType="submit" type="primary" loading={loadingg}>
                Change Password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  ) : (
    <div className="flex justify-center items-center h-screen">
      <h1>Not Authorized</h1>
    </div>
  );
}
