import axios from "axios";
import { useState, useContext } from "react";
import { List, Image, notification, Select, Form } from "antd";
import PageHeader from "../Layout/pageHeader";
import { Link } from "react-router-dom";
import LoadingAni from "../Layout/loading";
import AuthContext from "../../context/authContext";
import { Helmet } from "react-helmet";

export default function RetirementList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const authState = useContext(AuthContext);
    const token = authState.auth.token;
    const [dataa, setDataa] = useState([]);
    const [value, setValue] = useState([]);

    const [abc, setAbc] = useState(null)

    const handleSearch = (value) => {
        console.log(value);
        axios.get(`https://office.hempcarbonstandard.org/projectdetail/project-for-retirement?id=${value}`)
            .then(d => {
                setDataa(d.data)
                console.log(d.data);
            })
            .catch(e => {
                notification['error']({
                    message: "Error !!",
                    description: "Something went wrong fetching project."
                })
            })
    }


    const handleProjectValue = (value) => {
        setValue(value);
        setLoading(true);
        setAbc(dataa)
        axios
            .get(
                `https://office.hempcarbonstandard.org/projectdetail/retirement-on-projects?id=${value}`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )
            .then((d) => {
                setLoading(false);
                setData(d.data);
            })
            .catch((e) => {
                notification["error"]({
                    message: "Error !!",
                    description: "Something went wrong, try again.",
                });
                setLoading(false);
            });

    }



    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hemp Carbon Standard</title>
            </Helmet>

            <div
                className="W-[100%] bg-white rounded-md"
                style={{
                    background: "white",
                    borderRadius: "10px",
                    width: "100%",
                    minHeight: "70vh",
                }}
            >
                <PageHeader name="List - Projects" />

                <div style={{ marginTop: "30px", marginLeft: "20px", width: "280px" }}>
                    <Form>
                        <Form.Item
                            name="about_project"
                            label="Select Project"
                            rules={[
                                {
                                    required: true,
                                    message: "About Project missing !!",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                value={value}
                                style={{ width: "280px" }}
                                placeholder="start typing to get projects"
                                defaultActiveFirstOption={false}
                                suffixIcon={null}
                                filterOption={false}
                                onSearch={handleSearch}
                                onChange={handleProjectValue}
                                notFoundContent={null}
                                options={(dataa || []).map((d) => ({
                                    value: d.id,
                                    label: d.farm,
                                }))}
                            /></Form.Item>
                    </Form>
                </div>
                <List
                    style={{
                        background: "white",
                        width: "100%",
                        padding: "0px 24px",
                        paddingBottom: "8px",
                        borderRadius: "12px",
                    }}
                    loading={loading}
                    itemLayout='horizontal'
                    size="large"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item
                            key={item.id}
                        >
                            <List.Item.Meta
                                title={
                                    <Link
                                        to={`/console/uppdate-retirement?id=${item.id}`}
                                        style={{ color: "#1890ff" }}
                                    >
                                        {abc.length !== 0 && abc[0].farm}
                                    </Link>
                                }
                                description={
                                    <div>
                                        Beneficiary= <b>{item.beneficiary}</b>, Retirement Purpose= <b>{item.retirement_purpose}</b>,
                                        {" "}vintage={" "}
                                        <b>{item.vintage}</b>, Total retired in this vintag={" "}
                                        <b>
                                            {item.total_issued_in_this_vintage}
                                        </b>, Retirement Date = <b>{item.retirement_date}</b>
                                    </div>
                                }
                            />
                        </List.Item>
                    )}
                />
            </div>
        </>
    );
}
