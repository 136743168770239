import { useEffect, useState, useContext } from "react";
import {
    Form,
    Input,
    Row,
    Col,
    Button,
    message,
    Checkbox,
    Upload,
    Card,
    Modal,
    DatePicker,
    InputNumber,
    notification,
    // List,
    Select,
    // Image,
} from "antd";

import PageHeader from "../Layout/pageHeader";
import LoadingAni from "../Layout/loading";
import { useSearchParams } from "react-router-dom";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment/moment";
import AuthContext from "../../context/authContext";
import { Helmet } from "react-helmet";


const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

export default function RetirementUpdate() {
    const [searchParams] = useSearchParams();
    const authState = useContext(AuthContext);
    const token = authState.auth.token;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);;
    const [fileList, setFileList] = useState([]);
    // const [nameFileList, setNameFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [apiData, setApiData] = useState(null);
    const [fieldsNamesFromApi, setFieldsNamesFromApi] = useState(null);
    const [fieldsNamesToRemove, setFieldsNamesToRemove] = useState([]);

    const [data, setData] = useState([]);
    const [value, setValue] = useState();

    useEffect(() => {
        const id = searchParams.get("id");
        getDataForId(id);
    }, []);


    const handleSearch = (value) => {
        console.log(value);
        axios.get(`https://office.hempcarbonstandard.org/projectdetail/project-for-retirement?id=${value}`)
            .then(d => {
                setData(d.data)
                console.log(d.data);
            })
            .catch(e => {
                notification['error']({
                    message: "Error !!",
                    description: "Something went wrong fetching project."
                })
            })
    }


    const handleProjectValue = (value) => {
        setValue(value);
    }


    const getDataForId = (id) => {
        setLoading(true);
        axios
            .get(
                `https://office.hempcarbonstandard.org/projectdetail/retirement/${id}/`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            )

            .then((d) => {
                console.log(d.data);
                setApiData(d.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                notification["error"]({
                    message: "Error !!",
                    description: "Something went wrong try again.",
                });
            });
    };

    useEffect(() => {
        if (apiData) {
            form.setFieldsValue({
                project: apiData.project_name,
                beneficiary: apiData.beneficiary,
                retirement_purpose: apiData.retirement_purpose,
                country_of_consumption: apiData.country_of_consumption,
                methodology: apiData.methodology,
                vintage: apiData.vintage,
                total_issued_in_this_vintage: apiData.total_issued_in_this_vintage,

                credit_type: apiData.credit_type,
                cost: apiData.cost,
                retirement_date: moment(
                    apiData.retirement_date.toString(),
                    "YYYY-MM-DD"
                ),

            });
            // const aa = [
            //     {
            //         uid: "-1",
            //         name: "image.png",
            //         status: "done",
            //         url: apiData.picture_of_farm,
            //     },
            // ];
            // setFileList(aa);
            setFieldsNamesFromApi(apiData.filenames);

            // const shapeField = apiData?.shapefields?.map((d) => ({
            //   uid: d.id,
            //   name: "image.png",
            //   status: "done",
            //   url: d.img,
            // }));
            // setShapeSieldsFromApi(shapeField);
        }
    }, [apiData]);

    const formItemLayout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const buttonItemLayout = {
        wrapperCol: {
            span: 8,
            offset: 0,
        },
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 24,
                offset: 0,
            },
        },
    };


    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);



    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
            return Upload.LIST_IGNORE;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
            return Upload.LIST_IGNORE;
        }
        return false;
    };

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };

    const onFinish = (values) => {
        if (Array.isArray(fileList) && fileList.length === 0) {
            notification["error"]({
                message: "Error !!",
                description: (
                    <p>
                        Please add <b>Fram Image</b>
                    </p>
                ),
            });
        } else {
            const expected_validation_date =
                values.expected_validation_date.format("YYYY-MM-DD");
            const picture_of_farm = fileList[0]?.originFileObj;

            let data = new FormData();

            if (fileList[0]?.originFileObj) {
                data.append("picture_of_farm", picture_of_farm);
            }

            if (values.fieldNames) {
                const dd = values.fieldNames.map((d) => ({
                    a: d.first,
                }));
                data.append("fieldnames", JSON.stringify(dd));
            }

            if (values.fieldNames) {
                values.fieldNames.forEach((d) =>
                    data.append("fieldNamesImages", d.last.file)
                );
            }

            if (values.actual_carbon_credit) {
                data.append("actual_carbon_credit", values.actual_carbon_credit);
            }

            if (values.validation_completed_date) {
                const ddate = values.validation_completed_date.format("YYYY-MM-DD");
                data.append("validation_completed_date", ddate);
            }

            data.append("farm", values.farm);
            data.append("state", values.state);
            data.append("country", values.country);
            data.append("about_project", values.about_project);
            data.append("total_acres", values.total_acres);
            data.append("estimated_carbon_credit", values.estimated_carbon_credit);
            data.append("project_duration", values.project_duration);
            data.append("expected_validation_date", expected_validation_date);
            data.append("status", JSON.stringify(values.status));

            data.append("fieldsNamesToRemove", JSON.stringify(fieldsNamesToRemove));

            setLoading(true);
            axios({
                url: `https://office.hempcarbonstandard.org/projectdetail/create-project/${apiData.id}/`,
                method: "PUT",
                data,
                headers: {
                    Authorization: `Token ${token}`,
                    Accept: "application/json",
                },
            })
                .then((d) => {
                    setLoading(false);
                    form.resetFields();
                    setFieldsNamesToRemove([]);
                    // setNameFileList([]);
                    getDataForId(d.data.id);
                    notification["success"]({
                        message: "Success !!",
                        description: "Project Updated.",
                    });
                })
                .catch((e) => {
                    setLoading(false);
                    notification["error"]({
                        message: "Error !!",
                        description: "Something went wrong, try again.",
                    });
                });
        }
    };

    return loading ? (
        <LoadingAni />
    ) : (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hemp Carbon Standard</title>
            </Helmet>

            <div
                className="W-[100%] bg-white rounded-md"
                style={{
                    background: "white",
                    borderRadius: "10px",
                    width: "100%",
                }}
            >
                <PageHeader name="Update Retirement" />

                <Form
                    {...formItemLayout}
                    layout="vertical"
                    form={form}
                    scrollToFirstError
                    onFinish={onFinish}
                    className="bg-white w-full px-8 pt-0 pb-4 rounded-lg"
                    style={{
                        background: "white",
                        width: "100%",
                        padding: "0px 24px",
                        paddingBottom: "8px",
                        borderRadius: "12px",
                    }}
                >

                    <Row
                        gutter={[32, 32]}
                        className="pt-8"
                        style={{
                            paddingTop: "32px",
                        }}
                    >
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                name="project"
                                label="Farm / Project Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Project missing !!",
                                    },
                                ]}
                            >

                                <Select
                                    showSearch
                                    value={value}
                                    placeholder="Start typing to select project"
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onChange={handleProjectValue}
                                    notFoundContent={null}
                                    options={(data || []).map((d) => ({
                                        value: d.id,
                                        label: d.farm,
                                    }))}
                                />


                            </Form.Item>
                            <Form.Item
                                name="beneficiary"
                                label="Beneficiary"
                                rules={[
                                    {
                                        required: true,
                                        message: "Beneficiary missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input beneficiary" />
                            </Form.Item>
                            <Form.Item
                                name="retirement_purpose"
                                label="Retirement Purpose"
                                rules={[
                                    {
                                        required: true,
                                        message: "Retirement Purpose missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input retirement purpose" />
                            </Form.Item>



                            <Form.Item
                                name="country_of_consumption"
                                label="Country of Consumption"
                                rules={[
                                    {
                                        required: true,
                                        message: "Country of Consumption missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input country of consumption" />
                            </Form.Item>
                            <Form.Item
                                name="retirement_date"
                                label="Retirement Date"
                                rules={[
                                    {
                                        required: true,
                                        message: "Retirement Date missing !!",
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: "100%" }} placeholder='select retirement date' />
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                name="methodology"
                                label="Methodology"
                                rules={[
                                    {
                                        required: true,
                                        message: "Methodology missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input methodology" />
                            </Form.Item>
                            <Form.Item
                                name="vintage"
                                label="Vintage"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vintage missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input vintage" />
                            </Form.Item>

                            <Form.Item
                                name="total_issued_in_this_vintage"
                                label="Total Retired in this Vintage"
                                rules={[
                                    {
                                        required: true,
                                        message: "Total Retired in this Vintage missing !!",
                                    },
                                ]}
                            >

                                <InputNumber
                                    placeholder="input total issued in this vintage"
                                    controls={false}
                                    precision={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="credit_type"
                                label="Credit Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Credit Type missing !!",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="select credit type "
                                    options={[
                                        {
                                            value: 'HCS 15+',
                                            label: 'HCS 15+',
                                        },
                                        {
                                            value: 'HCS 100+',
                                            label: 'HCS 100+',
                                        },]}

                                />
                            </Form.Item>

                            <Form.Item
                                name="cost"
                                label="Cost/Unit"
                                rules={[
                                    {
                                        required: true,
                                        message: "Cost missing !!",
                                    },
                                ]}
                            >
                                <InputNumber
                                    placeholder="input cost"
                                    controls={false}
                                    precision={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>


                        </Col>
                    </Row>

                    <Row
                        gutter={[32, 32]}
                        className="pt-8"
                        style={{
                            paddingTop: "10px",
                        }}
                    >
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Card title="Documents" headStyle={{ background: "#f5f5f5" }}>
                                <Form.Item
                                    name="documents"
                                    label="Documents"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Documents missing !!",
                                        },
                                    ]}
                                >
                                    <Upload
                                        beforeUpload={beforeUpload}
                                        //   {...uploadProps}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                    >
                                        {fileList.length >= 10 ? null : uploadButton}
                                    </Upload>
                                </Form.Item>

                            </Card>
                        </Col>
                    </Row>

                    <br />
                    <Form.Item {...buttonItemLayout}>
                        <Button htmlType="submit" type="primary" disabled>
                            Create Retirements
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Modal
                open={previewOpen}
                title={previewTitle}
                width="55%"
                footer={null}
                onCancel={() => {
                    setPreviewOpen(false);
                }}
            >
                <img
                    alt="example"
                    style={{
                        width: "100%",
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );

}
