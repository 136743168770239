import { Route, Routes } from "react-router-dom";
import NotFound from "./notFound";
import Login from "./auth/login";
import ConsoelLayout from "./console/Layout/layout";

export default function Routerss() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="console/*" element={<ConsoelLayout />}>
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
