import React from 'react'
import { Helmet } from "react-helmet";
import { useState, useContext } from "react";
import {
    Form,
    Input,
    Row,
    Col,
    Button,
    message,
    Checkbox,
    Upload,
    Card,
    Modal,
    DatePicker, Select,
    InputNumber,
    notification,
} from "antd";

import PageHeader from "../Layout/pageHeader";
import LoadingAni from "../Layout/loading";
import AuthContext from "../../context/authContext";

import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment/moment";

const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;


export default function Retirement() {

    const authState = useContext(AuthContext);
    const token = authState.auth.token;



    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const [fileList, setFileList] = useState([]);
    const [nameFileList, setNameFileList] = useState([]);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");

    const [data, setData] = useState([]);
    const [value, setValue] = useState();

    const formItemLayout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const buttonItemLayout = {
        wrapperCol: {
            span: 8,
            offset: 0,
        },
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 24,
                offset: 0,
            },
        },
    };

    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };
    const beforeUpload = (file) => {
        // const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        const isJpgOrPng = file.type === "application/pdf";

        if (!isJpgOrPng) {
            message.error("You can only upload pdf file!");
            return Upload.LIST_IGNORE;
        }
        // const isLt2M = file.size / 1024 / 1024 < 2;
        // if (!isLt2M) {
        //   message.error("Image must smaller than 2MB!");
        //   return Upload.LIST_IGNORE;
        // }
        return false;
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

    const handleChangeNameFile = ({ fileList: newFileList }) => {
        if (Array.isArray(newFileList) && newFileList.length !== 0) {
            setNameFileList([...nameFileList, newFileList]);
        }
    };


    const handleSearch = (value) => {
        console.log(value);
        axios.get(`https://office.hempcarbonstandard.org/projectdetail/project-for-retirement?id=${value}`)
            .then(d => {
                setData(d.data)
                console.log(d.data);
            })
            .catch(e => {
                notification['error']({
                    message: "Error !!",
                    description: "Something went wrong fetching project."
                })
            })
    }


    const handleProjectValue = (value) => {
        setValue(value);
    }

    const onFinish = (values) => {

        console.log(values);
        // console.log(fileList);
        const retirement_date =
            values.retirement_date.format("YYYY-MM-DD");

        // const picture_of_farm = fileList[0].originFileObj;

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        let data = new FormData();



        if (values.documents) {
            values.documents.fileList.forEach((d) =>
                data.append("files", d.originFileObj)
            );
        }

        data.append("project_ins", value);
        data.append("beneficiary", values.beneficiary);
        data.append("retirement_purpose", values.retirement_purpose);
        data.append("country_of_consumption", values.country_of_consumption);
        data.append("vintage", values.vintage);
        data.append("total_issued_in_this_vintage", values.total_issued_in_this_vintage);
        data.append("credit_type", values.credit_type);
        data.append("cost", values.cost);
        data.append("retirement_date", retirement_date);

        setLoading(true);
        axios({
            url: "https://office.hempcarbonstandard.org/projectdetail/retirement/",
            method: "POST",
            data,
            headers: {
                Authorization: `Token ${token}`,
                Accept: "application/json",
            },
        })
            .then((d) => {
                setFileList([]);
                setNameFileList([]);
                // form.resetFields();
                setLoading(false);
                notification["success"]({
                    message: "Success !!",
                    description: "New Retirement Created",
                });
            })
            .catch((e) => {
                setLoading(false);
                notification["error"]({
                    message: "Error !!",
                    description: "Something went wrong, try again.",
                });
            });
    };



    return loading ? (
        <LoadingAni />
    ) : (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hemp Carbon Standard</title>
            </Helmet>
            <div
                className="W-[100%] bg-white rounded-md"
                style={{
                    background: "white",
                    borderRadius: "10px",
                    width: "100%",
                }}
            >
                <PageHeader name="New Retirement" />

                <Form
                    {...formItemLayout}
                    layout="vertical"
                    form={form}
                    scrollToFirstError
                    onFinish={onFinish}
                    className="bg-white w-full px-8 pt-0 pb-4 rounded-lg"
                    style={{
                        background: "white",
                        width: "100%",
                        padding: "0px 24px",
                        paddingBottom: "8px",
                        borderRadius: "12px",
                    }}
                >
                    <Row
                        gutter={[32, 32]}
                        className="pt-8"
                        style={{
                            paddingTop: "32px",
                        }}
                    >
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                name="project"
                                label="Farm / Project Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Project missing !!",
                                    },
                                ]}
                            >

                                <Select
                                    showSearch
                                    value={value}
                                    placeholder="Start typing to select project"
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onChange={handleProjectValue}
                                    notFoundContent={null}
                                    options={(data || []).map((d) => ({
                                        value: d.id,
                                        label: d.farm,
                                    }))}
                                />


                            </Form.Item>
                            <Form.Item
                                name="beneficiary"
                                label="Beneficiary"
                                rules={[
                                    {
                                        required: true,
                                        message: "Beneficiary missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input beneficiary" />
                            </Form.Item>
                            <Form.Item
                                name="retirement_purpose"
                                label="Retirement Purpose"
                                rules={[
                                    {
                                        required: true,
                                        message: "Retirement Purpose missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input retirement purpose" />
                            </Form.Item>



                            <Form.Item
                                name="country_of_consumption"
                                label="Country of Consumption"
                                rules={[
                                    {
                                        required: true,
                                        message: "Country of Consumption missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input country of consumption" />
                            </Form.Item>
                            <Form.Item
                                name="retirement_date"
                                label="Retirement Date"
                                rules={[
                                    {
                                        required: true,
                                        message: "Retirement Date missing !!",
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: "100%" }} placeholder='select retirement date' />
                            </Form.Item>

                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>

                            <Form.Item
                                name="vintage"
                                label="Vintage"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vintage missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input vintage" />
                            </Form.Item>

                            <Form.Item
                                name="total_issued_in_this_vintage"
                                label="Total Retired in this Vintage"
                                rules={[
                                    {
                                        required: true,
                                        message: "Total Retired in this Vintage missing !!",
                                    },
                                ]}
                            >

                                <InputNumber
                                    placeholder="input total issued in this vintage"
                                    controls={false}
                                    precision={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="credit_type"
                                label="Credit Type"
                                rules={[
                                    {
                                        required: true,
                                        message: "Credit Type missing !!",
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="select credit type "
                                    options={[
                                        {
                                            value: 'HCS 15+',
                                            label: 'HCS 15+',
                                        },
                                        {
                                            value: 'HCS 100+',
                                            label: 'HCS 100+',
                                        },]}

                                />
                            </Form.Item>

                            <Form.Item
                                name="cost"
                                label="Cost/Unit"
                                rules={[
                                    {
                                        required: true,
                                        message: "Cost missing !!",
                                    },
                                ]}
                            >
                                <InputNumber
                                    placeholder="input cost"
                                    controls={false}
                                    precision={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>


                        </Col>
                    </Row>

                    <Row
                        gutter={[32, 32]}
                        className="pt-8"
                        style={{
                            paddingTop: "10px",
                        }}
                    >
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Card title="Documents" headStyle={{ background: "#f5f5f5" }}>
                                <Form.Item
                                    name="documents"
                                    label="Documents"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Documents missing !!",
                                        },
                                    ]}
                                >
                                    <Upload
                                        beforeUpload={beforeUpload}
                                        //   {...uploadProps}
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                    >
                                        {fileList.length >= 10 ? null : uploadButton}
                                    </Upload>
                                </Form.Item>

                            </Card>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col xs={{ span: 24 }} md={{ span: 24 }}>
                            <Card
                                title="Field Name / Names"
                                headStyle={{ background: "#f5f5f5" }}
                            >
                                <Form.List name="fieldNames">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        width: "50%",
                                                    }}
                                                    key={key}
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "first"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Missing name",
                                                            },
                                                        ]}
                                                        style={{
                                                            width: "50%",
                                                        }}
                                                    >
                                                        <Input maxLength={240} placeholder="Name" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, "last"]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Missing upload",
                                                            },
                                                        ]}
                                                    >
                                                        <Upload
                                                            beforeUpload={beforeUpload}
                                                            showUploadList={{ showRemoveIcon: false }}
                                                            listType="picture-card"
                                                            fileList={nameFileList[key]}
                                                            onPreview={handlePreview}
                                                            onChange={handleChangeNameFile}
                                                            onRemove={() => {
                                                                let de = nameFileList.filter(
                                                                    (d) => d !== d[key]
                                                                );
                                                                setNameFileList(de);
                                                            }}
                                                        >
                                                            {nameFileList[key]
                                                                ? nameFileList[key].length >= 1
                                                                    ? null
                                                                    : uploadButton
                                                                : uploadButton}
                                                        </Upload>
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </div>
                                            ))}
                                            <Form.Item {...formItemLayoutWithOutLabel}>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add field names
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Card>
                        </Col>
                    </Row> */}



                    <br />
                    <Form.Item {...buttonItemLayout}>
                        <Button htmlType="submit" type="primary">
                            Create Retirements
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <Modal
                open={previewOpen}
                title={previewTitle}
                width="55%"
                footer={null}
                onCancel={() => {
                    setPreviewOpen(false);
                }}
            >
                <img
                    alt="example"
                    style={{
                        width: "100%",
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );

}
