import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

export default function PageHeader(props) {
  const { name } = props;
  const navigate = useNavigate();

  return (
    <div
      className="flex bg-[#f0f0f0] h-[50px] items-center rounded-t-md px-4"
      style={{
        display: "flex",
        background: "#f0f0f0",
        height: "50px",
        alignItems: "center",
        padding: "0px 10px",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px",
      }}
    >
      {name !== "Landing Page" && (
        <ArrowLeftOutlined
          style={{ color: "#1890ff" }}
          className="cursor-pointer bg-[resd]"
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      <p
        className="m-0 p-0 mt-[0px] ml-3 font-semibold text-[18px] bg-[redd]"
        style={{
          margin: 0,
          padding: 0,
          marginLeft: "6px",
        }}
      >
        {name}
      </p>
    </div>
  );
}
