import React, { useEffect, useContext } from "react";
import { Button, Form, Input, notification } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/authContext";
import axios from "axios";
import { Helmet } from "react-helmet";

export default function Login() {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const authState = useContext(AuthContext);

  const token = authState.auth.token;
  const loadingLogin = authState.auth.loadingLogin;

  useEffect(() => {
    const token = localStorage.getItem("token");

    authState.update({
      ...authState.auth,
      token: token,
    });
  }, []);

  useEffect(() => {
    if (token) navigate("/console");
  }, [token]);

  const onFinish = (values) => {
    authState.update({ ...authState.auth, loadingLogin: true });
    axios
      .post("https://office.hempcarbonstandard.org/dj-rest-auth/login/", {
        username: values.username,
        password: values.password,
      })
      .then((d) => {
        localStorage.setItem("token", d.data.key);
        authState.update({
          ...authState.auth,
          token: d.data.key,
          loadingLogin: false,
        });
      })
      .catch((e) => {
        authState.update({ ...authState.auth, loadingLogin: false });
        notification["error"]({
          message: "Error !!",
          description: "Someting went wrong, try again.",
        });
      });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hemp Carbon Standard</title>
      </Helmet>
      <div
        className=" h-screen flex justify-center items-center bg-[#fafafa]"
        style={{
          display: "flex",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          background: "#fafafa",
        }}
      >
        {/* <div className="absolute top-[10%]">
        <p className="font-bold">DMSnext</p>
      </div> */}
        <div
          className="w-2/5 rounded-md border-solid border-[#f0f0f0] border
       bg-[#fff] shadow-md"
          style={{
            width: "40%",
            borderRadius: "10px",
            border: "#f0f0f0",
            background: "#fff",
          }}
        >
          <p
            className="bg-[#f2f2f2] mt-0 px-4 py-3 rounded-md"
            style={{
              background: "#f2f2f2",
              marginTop: 0,
              padding: "10px 14px",
              borderTopLeftRadiusRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            Login
          </p>
          <div className="px-8 pt-10 pb-10" style={{ padding: "14px 12px" }}>
            <Form
              name="basic"
              form={form}
              labelCol={{
                span: 10,
              }}
              wrapperCol={{
                span: 14,
              }}
              labelAlign="left"
              onFinish={onFinish}
            >
              <Form.Item
                label="UserName"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your Username !",
                  },
                ]}
              >
                <Input prefix={<UserOutlined />} />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password !",
                  },
                ]}
              >
                <Input.Password prefix={<LockOutlined />} />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 10,
                  span: 14,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loadingLogin}>
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
