import { Skeleton } from "antd";
export default function LoadingAni() {
  return (
    <div
      style={{
        background: "#ffffff",
        minHeight: "60vh",
        height: "60vh",
        padding: "30px 16px",
      }}
    >
      <Skeleton
        avatar
        active
        paragraph={{
          rows: 4,
        }}
        // style={{
        //   background: "#ffffff",
        //   minHeight: "60vh",
        //   height: "60vh",
        // }}
      />
    </div>
  );
}
