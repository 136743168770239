import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import { useState, useContext } from "react";
import {
    Form,
    Input,
    Row,
    Col,
    Button,
    message,
    Upload,
    Card,
    // Modal,
    Select,
    List,
    InputNumber,
    notification,
} from "antd";

import PageHeader from "../Layout/pageHeader";
import LoadingAni from "../Layout/loading";
import AuthContext from "../../context/authContext";

import { PlusOutlined } from "@ant-design/icons";
import axios from "axios";



export default function UpdateProject() {

    const authState = useContext(AuthContext);
    const token = authState.auth.token;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);

    const [apiData, setApiData] = useState([])
    const [fieldsNamesFromApi, setFieldsNamesFromApi] = useState(null);
    const [fieldsNamesToRemove, setFieldsNamesToRemove] = useState([]);

    // const [previewOpen, setPreviewOpen] = useState(false);
    // const [previewImage, setPreviewImage] = useState("");
    // const [previewTitle, setPreviewTitle] = useState("");

    const [data, setData] = useState([]);
    const [value, setValue] = useState();

    const formItemLayout = {
        labelCol: {
            span: 24,
        },
        wrapperCol: {
            span: 24,
        },
    };

    const buttonItemLayout = {
        wrapperCol: {
            span: 8,
            offset: 0,
        },
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );


    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "application/pdf";

        if (!isJpgOrPng) {
            message.error("You can only upload pdf file!");
            return Upload.LIST_IGNORE;
        }

        return false;
    };
    const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);



    const handleSearch = (value) => {

        axios.get(`https://office.hempcarbonstandard.org/projectdetail/project-for-retirement?id=${value}`)
            .then(d => {
                setData(d.data)
            })
            .catch(e => {
                notification['error']({
                    message: "Error !!",
                    description: "Something went wrong fetching project."
                })
            })
    }


    const handleProjectValue = (value) => {
        setValue(value);
        setApiData([])

        axios.get(`https://office.hempcarbonstandard.org/projectdetail/update-project-on-project?id=${value}`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }).then(d => {
                setApiData(d.data)
                form.setFieldsValue({
                    methodology: d.data[0]?.methodology,
                    vintage: d.data[0]?.vintage,
                })
            })
            .catch(() => {
                notification['error']({
                    message: "Error",
                    description: "Error on geting data."
                })
            })


        axios.get(`https://office.hempcarbonstandard.org/projectdetail/project-docs-on-project?id=${value}`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }).then(d => {
                console.log("docs", d.data);
                setFieldsNamesFromApi(d.data)
            })
            .catch(() => {
                notification['error']({
                    message: "Error",
                    description: "Error on geting docs."
                })
            })
    }

    useEffect(() => {
        console.log("apidata", apiData);
    })

    const onFinish = (values) => {

        const config = {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        };

        let data = new FormData();
        if (values.documents) {
            values.documents.fileList.forEach((d) =>
                data.append("files", d.originFileObj)
            );
        }
        data.append("project_ins", value);
        data.append("methodology", values.methodology);
        data.append("vintage", values.vintage);
        data.append("fieldsNamesToRemove", JSON.stringify(fieldsNamesToRemove));


        setLoading(true);


        {
            apiData?.length === 0 ?

                axios({
                    url: "https://office.hempcarbonstandard.org/projectdetail/update-project/",
                    method: "POST",
                    data,
                    headers: {
                        Authorization: `Token ${token}`,
                        Accept: "application/json",
                    },
                })
                    .then((d) => {
                        setFileList([]);
                        form.resetFields();
                        setLoading(false);
                        notification["success"]({
                            message: "Success !!",
                            description: "Retirement Updated",
                        });
                    })
                    .catch((e) => {
                        setLoading(false);
                        notification["error"]({
                            message: "Error !!",
                            description: "Something went wrong, try again.",
                        });
                    })
                :


                axios({
                    url: `https://office.hempcarbonstandard.org/projectdetail/update-project/${apiData[0]?.id}/`,
                    method: "PUT",
                    data,
                    headers: {
                        Authorization: `Token ${token}`,
                        Accept: "application/json",
                    },
                })
                    .then((d) => {
                        setFileList([]);
                        form.resetFields();
                        setLoading(false);
                        notification["success"]({
                            message: "Success !!",
                            description: "Retirement Updated",
                        });
                    })
                    .catch((e) => {
                        setLoading(false);
                        notification["error"]({
                            message: "Error !!",
                            description: "Something went wrong, try again.",
                        });
                    })
        }


    };



    return loading ? (
        <LoadingAni />
    ) : (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hemp Carbon Standard</title>
            </Helmet>
            <div
                className="W-[100%] bg-white rounded-md"
                style={{
                    background: "white",
                    borderRadius: "10px",
                    width: "100%",
                }}
            >
                <PageHeader name="Update Project" />

                <Form
                    {...formItemLayout}
                    layout="vertical"
                    form={form}
                    scrollToFirstError
                    onFinish={onFinish}
                    className="bg-white w-full px-8 pt-0 pb-4 rounded-lg"
                    style={{
                        background: "white",
                        width: "100%",
                        padding: "0px 24px",
                        paddingBottom: "8px",
                        borderRadius: "12px",
                    }}
                >
                    <Row
                        gutter={[32, 32]}
                        className="pt-8"
                        style={{
                            paddingTop: "32px",
                        }}
                    >
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                name="project"
                                label="Farm / Project Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Project missing !!",
                                    },
                                ]}
                            >

                                <Select
                                    showSearch
                                    value={value}
                                    placeholder="Start typing to select project"
                                    defaultActiveFirstOption={false}
                                    suffixIcon={null}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onChange={handleProjectValue}
                                    notFoundContent={null}
                                    options={(data || []).map((d) => ({
                                        value: d.id,
                                        label: d.farm,
                                    }))}
                                />


                            </Form.Item>

                            <Form.Item
                                name="vintage"
                                label="Vintage"
                                rules={[
                                    {
                                        required: true,
                                        message: "Vintage missing !!",
                                    },
                                ]}
                            >
                                <InputNumber
                                    placeholder="input total issued in this vintage"
                                    controls={false}
                                    precision={0}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>


                        </Col>
                        <Col xs={{ span: 24 }} md={{ span: 12 }}>
                            <Form.Item
                                name="methodology"
                                label="Methodology"
                                rules={[
                                    {
                                        required: true,
                                        message: "Methodology missing !!",
                                    },
                                ]}
                            >
                                <Input maxLength={240} placeholder="input methodology" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Card title="Documents" headStyle={{ background: "#f5f5f5", width: "100%" }}>

                        <Row
                            gutter={[32, 32]}
                            className="pt-8"
                            style={{
                                paddingTop: "10px",
                            }}
                        >

                            {apiData?.length !== 0 &&


                                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                    <List
                                        style={{
                                            background: "white",
                                            width: "100%",
                                            padding: "0px 24px",
                                            paddingBottom: "8px",
                                            borderRadius: "12px",
                                        }}
                                        // loading={loading}
                                        itemLayout="vertical"
                                        size="small"
                                        dataSource={fieldsNamesFromApi ? fieldsNamesFromApi : []}
                                        renderItem={(item, i) => (
                                            <List.Item
                                                key={item.id}
                                            >
                                                <List.Item.Meta
                                                    title={<a href={item.doc} target="_blank" rel="noopener noreferrer">
                                                        File {i + 1}</a>}
                                                    description={
                                                        <Button
                                                            type="text"
                                                            danger
                                                            onClick={() => {
                                                                setFieldsNamesFromApi(
                                                                    fieldsNamesFromApi.filter(
                                                                        (d) => d.id !== item.id
                                                                    )
                                                                );
                                                                setFieldsNamesToRemove(
                                                                    (fieldsNamesToRemove) => [
                                                                        ...fieldsNamesToRemove,
                                                                        item.id,
                                                                    ]
                                                                );
                                                            }}
                                                        >
                                                            Remove
                                                        </Button>
                                                    }
                                                />
                                            </List.Item>
                                        )}
                                    />
                                </Col>
                            }


                            <Col xs={{ span: 24 }} md={{ span: 12 }}>
                                <Form.Item
                                    name="documents"
                                    label="Documents"
                                >
                                    <Upload
                                        beforeUpload={beforeUpload}
                                        //   {...uploadProps}
                                        listType="picture-card"
                                        fileList={fileList}
                                        // onPreview={handlePreview}
                                        onChange={handleChange}
                                    >
                                        {fileList.length >= 10 ? null : uploadButton}
                                    </Upload>
                                </Form.Item>

                            </Col>
                        </Row>
                    </Card>





                    <br />
                    <Form.Item {...buttonItemLayout}>
                        <Button htmlType="submit" type="primary">
                            Update Project
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            {/* <Modal
                open={previewOpen}
                title={previewTitle}
                width="55%"
                footer={null}
                onCancel={() => {
                    setPreviewOpen(false);
                }}
            >
                <img
                    alt="example"
                    style={{
                        width: "100%",
                    }}
                    src={previewImage}
                />
            </Modal> */}
        </>
    );

}
