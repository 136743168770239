import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routerss from "./routes";
import AuthState from "./context/authState";

function App() {
  return (
    <AuthState>
      <BrowserRouter>
        <Routerss />
      </BrowserRouter>
    </AuthState>
  );
}

export default App;
