import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { List, Image, notification } from "antd";
import PageHeader from "../Layout/pageHeader";
import { Link } from "react-router-dom";
import LoadingAni from "../Layout/loading";
import AuthContext from "../../context/authContext";
// import PageHeader from '../Layout/pageHeader'
import { Helmet } from "react-helmet";

export default function ListProjects() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const authState = useContext(AuthContext);
  const token = authState.auth.token;

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        "https://office.hempcarbonstandard.org/projectdetail/create-project/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((d) => {
        setLoading(false);
        setData(d.data);
      })
      .catch((e) => {
        notification["error"]({
          message: "Error !!",
          description: "Something went wrong, try again.",
        });
        setLoading(false);
      });
  }, []);

  return loading ? (
    <LoadingAni />
  ) : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hemp Carbon Standard</title>
      </Helmet>

      <div
        className="W-[100%] bg-white rounded-md"
        style={{
          background: "white",
          borderRadius: "10px",
          width: "100%",
          minHeight: "70vh",
        }}
      >
        <PageHeader name="List - Projects" />

        <List
          style={{
            background: "white",
            width: "100%",
            padding: "0px 24px",
            paddingBottom: "8px",
            borderRadius: "12px",
          }}
          loading={loading}
          itemLayout="vertical"
          size="large"
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              key={item.title}
              extra={
                <Image width={270} height={180} src={item.picture_of_farm} />
              }
            >
              <List.Item.Meta
                //   avatar={<Avatar src={item.avatar} />}
                title={
                  <Link
                    to={`/console/update?id=${item.id}`}
                    style={{ color: "#1890ff" }}
                  >
                    {item.farm}
                  </Link>
                }
                description={
                  <div>
                    State= <b>{item.state}</b>, Country= <b>{item.country}</b>
                    <br />
                    Estimated Carbon Credits={" "}
                    <b>{item.estimated_carbon_credit}</b>, Actual Carbon
                    Credits={" "}
                    <b>
                      {item.actual_carbon_credit
                        ? item.actual_carbon_credit
                        : "Not Defined"}
                    </b>
                    <br />
                    Total Acres= <b>{item.total_acres}</b>
                  </div>
                }
              />
              {item.about_project}
            </List.Item>
          )}
        />
      </div>
    </>
  );
}
